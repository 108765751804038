import axios from "axios";
import {cacheAdapterEnhancer} from "axios-extensions";
import {BASE_URL} from "./url";
import adapter from "axios/lib/adapters/xhr";

axios.defaults.adapter = adapter;

const cacheConfig = {
  enabledByDefault: false,
  cacheFlag: "useCache",
};

const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    "Accept": "application/json"
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, cacheConfig),
});

// This interceptor sets the authtoken automatically if it exists in the store
const authInterceptor = (config) => {
  const token = window.$store.state.auth.token;
  if (token) {
    config.params = {...config.params, authtoken: token};
  }
  return config;
};

// interceptor to catch errors
const errorInterceptor = (error) => {
  // check if it's a server error
  if (!error.response) {
    notify({
      title: "Network/Server error",
      type: "warn",
    });
    return Promise.reject(error);
  }

  // all the other error responses
  switch (error.response.status) {
    case 400:
      console.error(error.response.status, error.message);

      notify({
        title: "Nothing to display",
        text: "Data Not Found",
        type: "warn",
      });
      break;

    case 401: // authentication error, logoutAndRedirect the user
      notify({
        title: "Please login again",
        text: "Session Expired",
        type: "warn",
      });
      localStorage.removeItem("token");
      //   router.push("/auth");
      break;

    default:
      notify({
        title: error.response.status,
        text: error.message,
        type: "error",
      });
      console.error(error);
  }
  return Promise.reject(error);
};
httpClient.interceptors.request.use(authInterceptor);
export default httpClient;
