import * as requests from "@/API/requests";

const goalsStore = {
  namespaced: true,

  state: () => ({
    plan: {
      goals_ef: {},
      goals_ff: {},
      goals_cf: {},
    },
    dashboardGoals: {},
  }),
  mutations: {
    setPlan(state, payload) {
      state.plan = payload;
    },
    setDashboardGoals(state, payload) {
      state.dashboardGoals = payload;
    },
  },
  actions: {
    async loadGoals(store) {
      const response = await requests.loadGoalsRequest();
      store.commit("setPlan", response);
      return response;
    },
    async goalCalculator(store, payload) {
      return await requests.goalCalculatorRequest(payload);
    },

    async goalCalculatorUpdate(store, payload) {
      return await requests.goalCalculatorUpdateRequest(payload);
    },
    async emergencyFund(store, payload) {
      return await requests.emergencyFundRequest(payload);
    },
    async emergencyFundUpdate(store, payload) {
      return await requests.emergencyFundUpdateRequest(payload);
    },
    async updateFinance(store, payload) {
      return await requests.updateFinanceRequest(payload);
    },
    async updateFinancialFreedom(store, payload) {
      return await requests.financialFreedomUpdateRequest(payload);
    },
    async updateFinancialFreedomAllocation(store, payload) {
      return await requests.updateFinancialFreedomAllocationRequest(payload);
    },
    async calculateWishlistNper(store, payload) {
      return await requests.wishlistNperRequest(payload);
    },
    async addWishlist(store, payload) {
      return await requests.wishlistAddRequest(payload);
    },
    async deleteGoal(store, payload) {
      return await requests.goalDeleteRequest(payload);
    },
    async getMultiAssetGoals(store, payload) {
      return await requests.multiAssetGoalsRequest(payload);
    },

    async getGoalData(store, payload) {
      return await requests.getGoalDataRequest(payload);
    },

    async calculateWithdrawal(store, payload) {
      return await requests.calculateWithdrawalRequest(payload);
    },
    async requestWithdrawal(store, payload) {
      return await requests.requestWithdrawalRequest(payload);
    },

    async loadDashboardGoals(store) {
      const response = await requests.loadDashboardGoalsRequest();
      store.commit("setDashboardGoals", response);
      return response;
    },
    async loadWithdrawal() {
      return await requests.loadWithdrawalRequest();
    },
    async getGoalTypes() {
      return await requests.getGoalTypesRequest();
    },
    async getSuggestedMonthlySavings(_, payload) {
      return await requests.getSuggestedMonthlySavingsRequest(payload);
    },
    async calculateGoalAchieveDate(_, payload) {
      return await requests.calculateGoalAchieveDateRequest(payload);
    },
  },
  getters: {
    dashboardGoals(store) {
      const data = store.dashboardGoals;
      return {
        ef_set: data.goals_ef?.ef,
        slider_array: data.slider_array,
        savings: data.savings,
        ef_allocation: data.goals_ef?.allocation,
        available: data.goals_ef?.available,
        available_desc: data.goals_ef?.available_desc,
        graph_data: data.graph?.graph_data,
        graph_cat: data.graph?.graph_categories,
        total_invested: data.total_invested,
        age_ap: data.graph?.age,
      };
    },
    plan(store) {
      return store.plan;
    },
  },
};
export default goalsStore;
