import * as requests from "../API/requests";

const kycStore = {
  namespaced: true,

  state: () => ({
    kyc: null,
  }),
  mutations: {
    setKyc(state, payload) {
      state.kyc = payload;
    },
  },
  actions: {
    async runKyc(store, payload) {
      const data = await requests.kycRequest(payload);
      store.commit("setKyc", data);
      return data;
    },

    async sendOtp(store, payload) {
      const data = await requests.kycSendOtpRequest(payload);
      return data;
    },

    async verifyOtp(store, payload) {
      const data = await requests.kycVerifyOtpRequest(payload);
      return data;
    },

    async uploadKra(store, payload) {
      const data = await requests.kycUploadKraRequest(payload);
      return data;
    },

    async uploadBank(store, payload) {
      const data = await requests.kycUploadBankRequest(payload);
      return data;
    },


    async uploadId(store, payload) {
      const data = await requests.kycUploadIdRequest(payload);
      return data;
    },

    async uploadPhoto(store, payload) {
      const data = await requests.kycUploadPhotoRequest(payload);
      return data;
    },
    async updateNextOfKin(store, payload) {
      const data = await requests.kycUpdateNextOfKinRequest(payload);
      return data;
    },

    async loadData() {
      return await requests.kycLoadDataRequest();
    },

  },
  getters: {},
};

export default kycStore;
const routes = [
  {
    "id": "1l9t26iei",
    "name": "send otp"
  },
  {
    "id": "1l9t2qeom",
    "name": "verify otp"
  },
  {
    "id": "1l9t501sk",
    "name": "upload_kra"
  },
  {
    "id": "",
    "name": "upload_bank"
  },
  {
    "id": "1l9t5q9m0",
    "name": "upload_bank_1"
  },
  {
    "id": "1l9t5xmce",
    "name": "upload_address"
  },
  {
    "id": "1la19afvu",
    "name": "update_next_of_kin"
  },
  {
    "id": "1la19k96w",
    "name": "get_data"
  },
  {
    "id": "1ldird024",
    "name": "upload_ID_passport"
  },
  {
    "id": "1ldiri157",
    "name": "upload_photo"
  },
  {
    "id": "1lgxxbrro",
    "name": "upload_bank"
  }
]
