<script>
export default {
  data() {
    return {
      whitelisted_urls: ["/app/payment-wall", "/app/subscription-plan-payment"],
      paywall_url: "/app/payment-wall",
      paywall_disabled: true,
    };
  },
  methods: {
    handleUserSubscription() {
      if (this.paywall_disabled) {
        return;
      }
      // The paymentlink contains is either null or has
      // the uuid for a subscription payment transaction
      // It is what we will use to redirect the user to the paywall
      const { payment_link } = this.$store.state.auth.accountStatus;

      // We want the paywall to work only on post-auth pages
      if (!window.location.pathname.startsWith("/app")) {
        return;
      }
      // If the payment link is null or the user is in one of the whitelisted pages
      // then don't redirect
      if (
        !payment_link ||
        this.whitelisted_urls.includes(window.location.pathname)
      ) {
        return;
      }
      // redirect to the paywall page
      window.location.href = this.paywall_url;
    },
    async handleNotifications() {
      const notifications_data = this.$store.getters["auth/notifications"];

      const red = document.getElementById("red_dot");
      const bell = document.getElementById("bell_icon");

      if (!red || !bell) {
        return;
      }

      function handleClick() {
        let opacity = 1;
        const intervalId = setInterval(function () {
          opacity -= 0.1;
          red.style.opacity = opacity;
          if (opacity <= 0) {
            clearInterval(intervalId);
            red.style.display = "none";
          }
        }, 30);
      }

      bell?.addEventListener("click", handleClick);
      if (notifications_data.data?.length) {
        //if there are more than one show the red dot
        red.style.display = "block";
      }

      notifications_data.data?.forEach((item) => {
        console.log("Item:", item.data.title);

        // do something with each item here
        //href,message,cta,relativetime,type

        var href = item.data?.cta?.url || null;
        var cta = item.data?.cta?.text || null;

        var message = item.data.body;
        var relativetime = item.created_at;
        var type = item.data.type;

        const notification = renderNotification(
          href,
          message,
          cta,
          relativetime,
          type
        );
        var targetElement = document.getElementById("notifications_wrapper");
        targetElement.appendChild(notification);
      });
      function renderNotification(href, message, cta, relativetime, type) {
        if (href === null) {
          href = "#";
        }

        // Create the notification element
        const notification = document.createElement("a");
        notification.href = href;
        notification.classList.add("notification", "w-inline-block");
        notification.tabIndex = 0;

        // Create the notification icon wrapper element
        const iconWrapper = document.createElement("div");
        iconWrapper.id = "w-node-f15c6e8d-b1cd-a0b7-4ef0-0f730a7d068a-7a8f9f7b";
        iconWrapper.classList.add("notification-icon-wrapper");

        //Check type of notification to assign the correct icon
        var imageUrl;

        if (type === "danger") {
          imageUrl = "https://africaspocket.ongea.studio/lib/images/danger.png";
        } else if (type === "default") {
          imageUrl =
            "https://africaspocket.ongea.studio/lib/images/Default.png";
        } else if (type === "warning") {
          imageUrl =
            "https://africaspocket.ongea.studio/lib/images/warning.png";
        } else if (type === "info") {
          imageUrl =
            "https://africaspocket.ongea.studio/lib/images/warning.png";
        } else {
          imageUrl =
            "https://africaspocket.ongea.studio/lib/images/warning.png";
        }

        // Create the notification icon image element
        const iconImage = document.createElement("img");
        iconImage.src = imageUrl;
        iconImage.loading = "lazy";
        iconImage.alt = "";

        // Add the icon image element to the icon wrapper element
        iconWrapper.appendChild(iconImage);

        // Create the notification text wrapper element
        const textWrapper = document.createElement("div");
        textWrapper.id =
          "w-node-_48725992-3dd6-c55f-a1d9-a84faa777446-7a8f9f7b";
        textWrapper.classList.add("notification-text-wrapper");

        // Create the notification CTA wrapper element
        const ctaWrapper = document.createElement("div");
        ctaWrapper.classList.add("notification-cta-wrapper");

        // Create the notification text element
        const text = document.createElement("div");
        text.classList.add("text-size-small");
        text.textContent = message;

        // Create the verification button element
        const button = document.createElement("div");
        button.classList.add("button", "is-button-xsmall");

        if (cta === null) {
          cta = "#";
        }

        // Create the verification button text element
        const buttonText = document.createElement("div");
        buttonText.classList.add("text-size-tiny");
        buttonText.textContent = cta;

        // Create the margin-top element
        const mTop = document.createElement("div");
        mTop.classList.add("margin-top-custom");

        // Add the verification button text element to the verification button element
        button.appendChild(buttonText);

        //Add the button element to the mTop element
        mTop.appendChild(button);

        // Add the verification button element to the CTA wrapper element
        ctaWrapper.appendChild(text);
        //ctaWrapper.appendChild(document.createElement("div").classList.add("margin-top-custom"));
        if (cta !== "#") {
          ctaWrapper.appendChild(mTop);
        }

        // Add the CTA wrapper element to the notification text wrapper element
        textWrapper.appendChild(ctaWrapper);

        // Create the notification time wrapper element
        const timeWrapper = document.createElement("div");
        timeWrapper.id =
          "w-node-_47cba3ff-dad5-5006-37a4-4cdaa0c1d173-7a8f9f7b";
        timeWrapper.classList.add("notification-time-wrapper");

        // Create the notification time text element
        const time = document.createElement("div");
        time.classList.add("text-size-tiny", "text-color-ap-blue-50");
        time.textContent = getRelativeTime(relativetime);

        // Add the notification time text element to the notification time wrapper element
        timeWrapper.appendChild(time);

        // Add the icon wrapper, text wrapper, and time wrapper elements to the notification element
        notification.appendChild(iconWrapper);
        notification.appendChild(textWrapper);
        notification.appendChild(timeWrapper);

        // Return the completed notification element
        return notification;
      }

      function getRelativeTime(timestamp) {
        const currentTime = new Date().getTime(); // Get the current time in milliseconds
        const timestampSeconds = new Date(timestamp).getTime() / 1000; // Convert the Unix timestamp to seconds
        const differenceSeconds = currentTime / 1000 - timestampSeconds; // Calculate the difference between the current time and the timestamp in seconds

        // Define the time intervals in seconds
        const minute = 60;
        const hour = 60 * minute;
        const day = 24 * hour;
        const week = 7 * day;
        const month = 30 * day;
        const year = 365 * day;

        // Determine the relative time from the timestamp
        if (differenceSeconds < minute) {
          return "just now";
        } else if (differenceSeconds < hour) {
          const minutes = Math.floor(differenceSeconds / minute);
          return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
        } else if (differenceSeconds < day) {
          const hours = Math.floor(differenceSeconds / hour);
          return `${hours} hour${hours === 1 ? "" : "s"} ago`;
        } else if (differenceSeconds < week) {
          const days = Math.floor(differenceSeconds / day);
          return `${days} day${days === 1 ? "" : "s"} ago`;
        } else if (differenceSeconds < month) {
          const weeks = Math.floor(differenceSeconds / week);
          return `${weeks} week${weeks === 1 ? "" : "s"} ago`;
        } else if (differenceSeconds < year) {
          const months = Math.floor(differenceSeconds / month);
          return `${months} month${months === 1 ? "" : "s"} ago`;
        } else {
          const years = Math.floor(differenceSeconds / year);
          return `${years} year${years === 1 ? "" : "s"} ago`;
        }
      }
    },
    logoutAndRedirect(e) {
      e.preventDefault();

      this.logout();
      window.location.href = "/auth/logout";
    },
    logout() {
      localStorage.clear();
      sessionStorage.clear();
    },

    authMiddleWare() {
      if (
        window.location.pathname.startsWith("/app") &&
        window.location.pathname !== "/app/subscription-plan-payment" &&
        window.location.pathname !== "/app/payment-success" &&
        window.location.pathname !== "/app/payment-failed"
      ) {
        if (!this.$store.state.auth.token) {
          window.location.href = window.location.origin + "/auth/log-in";
        }
      }
    },
  },
  created() {},
  async mounted() {
    this.authMiddleWare();

    if (this.$store.state.auth.token) {
      const logoutButton = document.querySelector(`a[href="/auth/logout"]`);
      if (logoutButton) {
        logoutButton.addEventListener("click", this.logoutAndRedirect);
      }
      const dateDifferenceInMinutes = () => {
        return Math.round(
          ((new Date().getTime() -
            new Date(
              this.$store.state.auth.notification_last_fetched_at
            ).getDate()) /
            (1000 * 60)) %
            60
        );
      };
      if (
        dateDifferenceInMinutes() > 20 &&
        window.location.pathname.startsWith("/app")
      ) {
        this.$store.dispatch("auth/checkAccountStatus");
        this.$store
          .dispatch("auth/getNotifications")
          .then(this.handleNotifications)
          .catch((err) => {
            if (err.response?.status === 401) {
              this.logout();
            }
          });
      }
    }
  },
};
</script>
