import {createApp, defineAsyncComponent} from "vue";
import App from "./App.vue";
import store from "./store";

// Import without options
const MakeInvestment = defineAsyncComponent(() =>
  import("@/views/app/MakeAnInvestmentView.vue")
);
const FinancialFreedomOnboardingView = defineAsyncComponent(() =>
  import("@/views/onboarding/FinancialFreedomOnboardingView.vue")
);
const SignUp = defineAsyncComponent(() =>
  import("@/views/auth/SignUpView.vue")
);
const GoogleLoad = defineAsyncComponent(() =>
  import("@/views/auth/GoogleLoadView.vue")
);
const LoginView = defineAsyncComponent(() =>
  import("@/views/auth/LoginView.vue")
);
const RouteView = defineAsyncComponent(() =>
  import("@/views/auth/RouteView.vue")
);
const VerifyEmailView = defineAsyncComponent(() =>
  import("@/views/auth/VerifyEmailView.vue")
);
const ForgotPasswordView = defineAsyncComponent(() =>
  import("@/views/auth/ForgotPasswordView.vue")
);
const AccountVerifiedView = defineAsyncComponent(() =>
  import("@/views/auth/AccountVerifiedView.vue")
);
const AccountVerifiedPhoneNumberView = defineAsyncComponent(() =>
  import("@/views/auth/AccountVerifiedPhoneNumberView.vue")
);
const ResetPasswordView = defineAsyncComponent(() =>
  import("@/views/auth/ResetPasswordView.vue")
);
const FinancialFreedomCalculatorView = defineAsyncComponent(() =>
  import("@/views/onboarding/FinancialFreedomCalculatorView.vue")
);
const LoadFinancialFreedomResultView = defineAsyncComponent(() =>
  import("@/views/onboarding/LoadFinancialFreedomResultView.vue")
);
const FinancialFreedomResultView = defineAsyncComponent(() =>
  import("@/views/onboarding/FinancialFreedomResultView.vue")
);
const FinancialFreedomProgressView = defineAsyncComponent(() =>
  import("@/views/onboarding/FinancialFreedomProgressView.vue")
);
const ManageYourGoalsView = defineAsyncComponent(() =>
  import("@/views/app/ManageYourGoalsView.vue")
);
const LoadingView = defineAsyncComponent(() =>
  import("@/views/app/LoadingView.vue")
);
const DashboardView = defineAsyncComponent(() =>
  import("@/views/app/DashboardView.vue")
);
const GoalView = defineAsyncComponent(() => import("@/views/app/GoalView.vue"));
const AccountSettingsView = defineAsyncComponent(() =>
  import("@/views/app/AccountSettingsView.vue")
);
const KycInformationView = defineAsyncComponent(() =>
  import("@/views/app/KycInformationView.vue")
);
const SubscriptionSettingsView = defineAsyncComponent(() =>
  import("@/views/app/SubscriptionSettingsView.vue")
);
const WithdrawView = defineAsyncComponent(() =>
  import("@/views/app/WithdrawView.vue")
);
const CompoundInterestCalculatorView = defineAsyncComponent(() =>
  import("@/views/calculators/CompoundInterestCalculatorView.vue")
);

const app = createApp(App);
app.use(store);
app.component("investment-page", MakeInvestment);
app.component("financial-freedom-onboarding", FinancialFreedomOnboardingView);
app.component("sign-up", SignUp);
app.component("google-load", GoogleLoad);
app.component("log-in", LoginView);
app.component("route-page", RouteView);
app.component("verify-email", VerifyEmailView);
app.component("forgot-password", ForgotPasswordView);
app.component("account-verified", AccountVerifiedView);
app.component("account-verified-phone-number", AccountVerifiedPhoneNumberView);
app.component("reset-password", ResetPasswordView);
app.component("financial-freedom-calculator", FinancialFreedomCalculatorView);
app.component("load-financial-freedom-result", LoadFinancialFreedomResultView);
app.component("financial-freedom-result", FinancialFreedomResultView);
app.component("financial-freedom-progress", FinancialFreedomProgressView);
app.component("manage-your-goals", ManageYourGoalsView);
app.component("app-loading", LoadingView);
app.component("app-dashboard", DashboardView);
app.component("app-goal", GoalView);
app.component("account-settings", AccountSettingsView);
app.component("kyc-information", KycInformationView);
app.component("subscription-settings", SubscriptionSettingsView);
app.component("app-withdraw", WithdrawView);
app.component("compound-interest-calculator", CompoundInterestCalculatorView);
// app.mount(".root");

if (!document.querySelector(".root")) {
  app.mount("section.section-inner"); //Don't commit this line when you change it
} else {
  app.mount(".root"); //Don't commit this line when you change it
}
window.$store = store;
