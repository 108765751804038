import financialFreedomCalculator from "@/store/calculators/financialFreedom.store";
import otherCalculators from "@/store/calculators/calculators.store";

export const calculatorsStore = {
  modules: {
    ff: financialFreedomCalculator,
    calculators: otherCalculators,
  },
};

export default calculatorsStore;
