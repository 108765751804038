import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";
import authStore from "./auth.store";
import kycStore from "./kyc.store";
import makeInvestmentStore from "./makeInvestment.store";
import calculatorsStore from "@/store/calculators";
import goalsStore from "@/store/goals.store";


export default createStore({
  modules: {
    auth: authStore,
    makeInvestment: makeInvestmentStore,
    kyc: kycStore,
    calculator: calculatorsStore,
    goals: goalsStore
  },
  plugins: [createPersistedState()],
});
