import * as requests from "@/API/requests";

const otherCalculators = {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async calculateCompoundInterest(store, payload) {
      return await requests.compoundInterestCalculatorRequest(payload);
    },
    async calculatePmt(store, payload) {
      return await requests.calculatePmtRequest(payload);
    },
  },
  getters: {},
};

export default otherCalculators;
